import React, { useEffect } from 'react';

const RedirectToWhatsApp = () => {
  useEffect(() => {
    // Redirect to the WhatsApp link
    window.location.href = 'https://wa.me/919899417056';
  }, []);

  return (
    <div>
      <p>Redirecting to WhatsApp...</p>
    </div>
  );
};

export default RedirectToWhatsApp;
