import React, { useState } from "react";


const images = [
  { id: 1, src: "./images/delivery-card.png", title: "Image 1" },
  { id: 2, src: "./images/feedback.png", title: "Image 2" },
 
];

function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container py-5">
    <div className="App">
      <h1>Gallery</h1>
      <div className="gallery-grid ">
        {images.map((image) => (
          <div key={image.id} className="gallery-item" onClick={() => openImage(image)}>
            <img src={image.src} alt={image.title} />
           
          </div>
        ))}
      </div>

      {/* Modal for selected image */}
      {selectedImage && (
        <div className="modal" onClick={closeImage}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage.src} alt={selectedImage.title} />
         
        </div>
      )}
    </div>
    </div>
  );
}

export default Gallery;
